import { Loading } from '@pelando/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  AuthProvider,
  FacebookLoginData,
  LoginComponentSourceName,
} from '../../types';
import AcceptTerms from '../AcceptTerms';
import EmailStep from '../EmailStep';
import LoginEmailSocialStep from '../LoginEmailSocialStep';
import LoginStep from '../LoginStep';
import SignupStep from '../SignupStep';
import SocialEmailConfirmation from '../SocialEmailConfirmation';
import Wizard from '../Wizard';
import { AuthenticationSteps, AuthenticationWizardProps } from './types';
import IncentiveSocialLoginStep from '../../IncentiveSocialLoginStep';

function AuthenticationWizard({
  loading,
  customError,
  modalSubtitle,
  modalTitle,
  defaultProvider,
  getAuthProviders,
  handleFacebookAuthentication,
  handleGoogleAuthentication,
  handleAppleAuthentication,
  handleEmailAuthentication,
  handleUpdateEmail,
  handleEmailSignup,
  handleAuthModal,
  handleStepChange,
  wizardControls,
  googleClientId,
  onCloseModal,
  handleRequestPasswordReset,
}: AuthenticationWizardProps) {
  const [email, setEmail] = useState<string>();
  const [defaultStep, setDefaultStep] = useState<AuthenticationSteps>(
    defaultProvider
      ? AuthenticationSteps.ACCEPT_TERMS
      : AuthenticationSteps.SOCIAL_LOGIN
  );

  const [socialLogin, setSocialLogin] = useState<AuthProvider>(
    AuthProvider.LOCAL
  );
  const [facebookData, setFacebookData] = useState<FacebookLoginData>();
  const acceptTermsCallback = useRef(() => {});

  const steps = Object.values(AuthenticationSteps);

  const onClickFacebook = useCallback(async () => {
    const facebookLoginData = await handleFacebookAuthentication();
    if (facebookLoginData.requestEmailUpdate) {
      setFacebookData(facebookLoginData);

      setDefaultStep(AuthenticationSteps.SOCIAL_EMAIL_CONFIRMATION);
    }
  }, [handleFacebookAuthentication]);

  useEffect(() => {
    if (defaultProvider) {
      const functionByProvider = {
        [AuthProvider.FACEBOOK]: onClickFacebook,
        [AuthProvider.GOOGLE]: handleGoogleAuthentication,
        [AuthProvider.LOCAL]: () =>
          setDefaultStep(AuthenticationSteps.LOGIN_STEP),
        [AuthProvider.APPLE]: handleAppleAuthentication,
      };

      acceptTermsCallback.current = functionByProvider[defaultProvider];

      setSocialLogin(defaultProvider);
      setDefaultStep(AuthenticationSteps.ACCEPT_TERMS);
    }
  }, [
    defaultProvider,
    handleAppleAuthentication,
    handleFacebookAuthentication,
    handleGoogleAuthentication,
    onClickFacebook,
  ]);

  return (
    <Wizard
      steps={[...steps] as const}
      onStepChange={handleStepChange}
      defaultStep={defaultStep}
      renderStep={({
        currentStep,
        goToStep,
        goToNextStep,
        goToPreviousStep,
      }) => {
        if (wizardControls) {
          /* eslint-disable no-param-reassign */
          wizardControls.current = {
            currentStep,
            goToStep,
            goToNextStep,
            goToPreviousStep,
          };
        }

        const onClickFacebook = async () => {
          const facebookLoginData = await handleFacebookAuthentication();

          if (facebookLoginData.requestEmailUpdate) {
            setFacebookData(facebookLoginData);

            goToStep(AuthenticationSteps.SOCIAL_EMAIL_CONFIRMATION);
          }
        };

        const handlePickAuth = (provider: AuthProvider) => {
          const functionByProvider = {
            [AuthProvider.FACEBOOK]: onClickFacebook,
            [AuthProvider.GOOGLE]: handleGoogleAuthentication,
            [AuthProvider.LOCAL]: () =>
              goToStep(AuthenticationSteps.LOGIN_STEP),
            [AuthProvider.APPLE]: handleAppleAuthentication,
          };

          if (provider !== AuthProvider.LOCAL) {
            acceptTermsCallback.current = functionByProvider[provider];

            setSocialLogin(provider);
            goToStep(AuthenticationSteps.ACCEPT_TERMS);
            return;
          }
          functionByProvider[provider]();
        };

        const handleSubmitEmail = (authEmail: string) => {
          setEmail(authEmail);
          getAuthProviders(authEmail)
            .then((response) => {
              if (response) {
                const isLocal = response.find(
                  (provider) => provider === 'local'
                );
                const functionByProvider = {
                  [AuthProvider.LOCAL]: () =>
                    goToStep(AuthenticationSteps.LOGIN_STEP),
                  [AuthProvider.FACEBOOK]: () =>
                    goToStep(AuthenticationSteps.LOGIN_EMAIL_SOCIAL_STEP),
                  [AuthProvider.GOOGLE]: () =>
                    goToStep(AuthenticationSteps.LOGIN_EMAIL_SOCIAL_STEP),
                  [AuthProvider.APPLE]: () =>
                    goToStep(AuthenticationSteps.LOGIN_EMAIL_SOCIAL_STEP),
                };
                const provider = isLocal
                  ? AuthProvider.LOCAL
                  : (response[0] as AuthProvider);

                setSocialLogin(provider);
                functionByProvider[provider]();
              } else {
                goToStep(AuthenticationSteps.SIGNUP_STEP);
              }
            })
            .catch(() => {
              goToStep(AuthenticationSteps.SIGNUP_STEP);
            });
        };

        if (loading) {
          return <Loading />;
        }

        const renderAcceptTerms = () => (
          <AcceptTerms
            onSubmit={acceptTermsCallback.current}
            socialLogin={socialLogin}
            onGoBack={() => goToStep(AuthenticationSteps.SOCIAL_LOGIN)}
          />
        );

        const renderLoginStep = () => (
          <LoginStep
            email={email}
            customError={customError}
            handleEmailAuthentication={handleEmailAuthentication}
            handleAuthModal={handleAuthModal}
            onGoBack={() => goToStep(AuthenticationSteps.EMAIL_STEP)}
          />
        );

        const renderSignupStep = () =>
          email ? (
            <SignupStep
              onSubmit={(emailValue, password) => {
                handleEmailSignup({ email: emailValue, password });
              }}
              email={email}
              customError={customError}
              onGoBack={() => goToStep(AuthenticationSteps.EMAIL_STEP)}
            />
          ) : null;

        const renderSocialEmailConfirmation = () =>
          facebookData ? (
            <SocialEmailConfirmation
              user={facebookData.user}
              onSubmit={handleUpdateEmail}
              customError={customError}
              onGoBack={() => goToStep(AuthenticationSteps.SOCIAL_LOGIN)}
            />
          ) : null;

        const renderSocialLoginStep = () => (
          <IncentiveSocialLoginStep
            onGoToEmailStep={() => goToStep(AuthenticationSteps.EMAIL_STEP)}
            modalTitle={modalTitle}
            modalSubtitle={modalSubtitle}
            onCloseModal={onCloseModal}
            componentSourceName={LoginComponentSourceName.authModal}
            googleClientId={googleClientId}
            onPick={handlePickAuth}
          />
        );

        const renderEmailStep = () => (
          <EmailStep
            onPick={handlePickAuth}
            handleSubmitEmail={handleSubmitEmail}
            modalTitle={modalTitle}
            modalSubtitle={modalSubtitle}
            onCloseModal={onCloseModal}
            onGoBack={() => goToStep(AuthenticationSteps.SOCIAL_LOGIN)}
          />
        );

        const renderLoginEmailSocialStep = () => (
          <LoginEmailSocialStep
            onPick={handlePickAuth}
            email={email}
            socialLogin={socialLogin}
            handleRequestPasswordReset={handleRequestPasswordReset}
            onGoBack={() => goToStep(AuthenticationSteps.SOCIAL_LOGIN)}
          />
        );

        const renderByCurrentStep = {
          [AuthenticationSteps.SOCIAL_LOGIN]: renderSocialLoginStep,
          [AuthenticationSteps.EMAIL_STEP]: renderEmailStep,
          [AuthenticationSteps.SIGNUP_STEP]: renderSignupStep,
          [AuthenticationSteps.LOGIN_STEP]: renderLoginStep,
          [AuthenticationSteps.ACCEPT_TERMS]: renderAcceptTerms,
          [AuthenticationSteps.SOCIAL_EMAIL_CONFIRMATION]:
            renderSocialEmailConfirmation,
          [AuthenticationSteps.LOGIN_EMAIL_SOCIAL_STEP]:
            renderLoginEmailSocialStep,
        };

        const renderCurrentStep = renderByCurrentStep[currentStep];

        return renderCurrentStep();
      }}
    />
  );
}

export default AuthenticationWizard;
