import { BannerType, useModal, useToast } from '@pelando/components';
import React, { ReactNode, useRef, useState } from 'react';

import { useTranslation } from '../../../../hooks/useTranslation';
import {
  AppleLoginData,
  AuthKind,
  AuthModalHandler,
  AuthProvider,
  FacebookLoginData,
  GoogleLoginData,
  LoginComponentSourceName,
} from '../../types';
import AuthenticationWizard from '../AuthenticationWizard';
import {
  AuthenticationWizardControls,
  AuthenticationWizardProps,
} from '../AuthenticationWizard/types';
import { AuthModalContainer } from './style';

export type LoginProps = {
  modalTitle?: ReactNode;
  modalSubtitle?: ReactNode;
  getAuthProviders: (email: string) => Promise<Array<string> | undefined>;
  handleAuthModal: AuthModalHandler;
  handleBasicLogin: (email: string, password: string) => Promise<void>;
  handleFacebookLogin: () => Promise<FacebookLoginData>;
  handleGoogleLogin: () => Promise<GoogleLoginData>;
  handleAppleLogin: () => Promise<AppleLoginData>;
  handleBasicSignup: (
    email: string,
    password: string
  ) => Promise<void | undefined>;
  updateEmail: (email: string) => Promise<void>;
  onCloseModal: () => void;
  onFinishSuccessfully?: () => void;
  handleRequestPasswordReset: (email: string) => Promise<boolean>;
  className?: string;
  isLogged?: boolean;
  defaultProvider?: AuthProvider;
  componentSourceName?: LoginComponentSourceName;
  path?: string;
  googleClientId?: string;
};

function Login({
  modalTitle,
  modalSubtitle,
  className,
  defaultProvider,
  getAuthProviders,
  handleAuthModal,
  handleBasicLogin,
  handleFacebookLogin,
  handleGoogleLogin,
  handleAppleLogin,
  updateEmail,
  handleBasicSignup,
  onFinishSuccessfully,
  handleRequestPasswordReset,
  onCloseModal,
  componentSourceName,
  path,
  googleClientId,
}: LoginProps) {
  const { t } = useTranslation('login');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { showToast } = useToast();
  const { closeModal } = useModal();
  const wizardControlesRef = useRef<AuthenticationWizardControls | undefined>();

  const finishSuccessfully = () => {
    try {
      onFinishSuccessfully?.();
      closeModal();
    } catch (error) {
      showToast({
        titleText: t('login-toast-banner-error-title'),
        topPosition: 0,
        text: t('login-toast-banner-error-text'),
        bannerType: BannerType.ERROR,
      });
    }
  };

  const finishWithError = (error: Error) => {
    const message = error?.message?.split(':', 2);
    showToast({
      topPosition: 0,
      text: t('login-toast-banner-email-error'),
      bannerType: BannerType.ERROR,
    });
    if (message) {
      setErrorMessage(message[0]);
    }
    setLoading(false);
  };

  const finishUpdateEmailWithError = () => {
    showToast({
      titleText: t('login-toast-banner-error-title'),
      topPosition: 0,
      text: t('login-toast-banner-email-change-error'),
      bannerType: BannerType.ERROR,
    });
    closeModal();
  };

  const handleEmailLogin: AuthenticationWizardProps['handleEmailAuthentication'] =
    ({ email, password }) => {
      setLoading(true);
      handleBasicLogin(email, password)
        .then(finishSuccessfully)
        .catch(({ error }: { error: Error }) => {
          finishWithError(error);
        });
    };

  const handleUpdateEmail: AuthenticationWizardProps['handleUpdateEmail'] = (
    email
  ) => {
    setLoading(true);
    updateEmail(email)
      .then(() => finishSuccessfully())
      .catch(finishUpdateEmailWithError);
  };

  const closeOnReturninguserProviderData: AuthenticationWizardProps['handleFacebookAuthentication'] =
    async () => {
      const facebookData = await handleFacebookLogin();

      if (!facebookData.requestEmailUpdate) {
        finishSuccessfully();
      }

      return facebookData;
    };

  const closeOnReturningGoogleUser: AuthenticationWizardProps['handleGoogleAuthentication'] =
    async () => {
      const googleData = await handleGoogleLogin();

      return googleData;
    };

  const closeOnReturningAppleUser: AuthenticationWizardProps['handleAppleAuthentication'] =
    async () => {
      const appleData = await handleAppleLogin();

      return appleData;
    };

  const handleEmailSignUp: AuthenticationWizardProps['handleEmailSignup'] = ({
    email,
    password,
  }) => {
    setLoading(true);
    handleBasicSignup(email, password)
      .then(() => finishSuccessfully())
      .catch(({ error }: { error: Error }) => {
        finishWithError(error);
      });
  };

  const handleStepChange = () => {};

  const content = (
    <AuthenticationWizard
      defaultProvider={defaultProvider}
      authKind={AuthKind.LOGIN}
      loading={loading}
      wizardControls={wizardControlesRef}
      customError={errorMessage}
      modalTitle={modalTitle}
      modalSubtitle={modalSubtitle}
      componentSourceName={componentSourceName}
      path={path}
      googleClientId={googleClientId}
      handleStepChange={handleStepChange}
      handleEmailAuthentication={handleEmailLogin}
      handleEmailSignup={handleEmailSignUp}
      handleAuthModal={handleAuthModal}
      handleFacebookAuthentication={closeOnReturninguserProviderData}
      handleGoogleAuthentication={closeOnReturningGoogleUser}
      handleAppleAuthentication={closeOnReturningAppleUser}
      handleUpdateEmail={handleUpdateEmail}
      getAuthProviders={getAuthProviders}
      handleRequestPasswordReset={handleRequestPasswordReset}
      onCloseModal={onCloseModal}
    />
  );

  return (
    <AuthModalContainer className={className}>
      <div>{content}</div>
    </AuthModalContainer>
  );
}

export default Login;
